function btnHoverVariants( variant ){
    const btnHoverVariants = {
        "primary" : ' hover:bg-gray-800',
        "success" : ' hover:bg-green-600',
        "info" : ' hover:bg-blue-700',
        "warning" : ' hover:bg-yellow-600',
        "danger" : ' hover:bg-red-800',

        "primary-outline": ' hover:text-gray-50 hover:bg-gray-800',
        "success-outline": ' hover:text-green-50 hover:bg-green-600',
        "info-outline": ' hover:text-blue-50 hover:bg-blue-700',
        "warning-outline": ' hover:text-yellow-50 hover:bg-yellow-600',
        "danger-outline": ' hover:text-red-50 hover:bg-red-800',
    }
    return btnHoverVariants[variant] || '';
}

function inputHoverVariants( variant ){
    const btnHoverVariants = {
        "primary": ' focus:text-gray-800 focus:outline-none focus:shadow hover:border-gray-300',
        "success": ' focus:text-gray-800  focus:outline-none focus:shadow  hover:border-green-800',
        "info": ' focus:text-gray-800 focus:outline-none focus:shadow  hover:border-blue-800',
        "warning": ' focus:text-gray-800 focus:outline-none focus:shadow  hover:border-yellow-800',
        "danger": ' focus:text-gray-800 focus:outline-none focus:shadow hover:border-red-800',
    }

    return btnHoverVariants[ variant ] || '';
}

function getColorByCode( code, bg = false ){

    const colors = {
        text: bg ? 'text-green-900':'text-green-500',
        bg: bg ? 'bg-green-200':' ',
        b: bg ? 'border border-green-300':''
    };


    switch ( Number(code) ){
        case 400:
        case 401:
        case 402:
        case 403:
        case 404:
        case 421:
        case 422:
        case 429:
        case 500:
            colors.text = bg ? 'text-red-900':'text-red-500';
            colors.bg =  bg ? 'bg-red-200':'';
            colors.b = bg ? 'border border-red-300':'';
            break;

    }

     const _color = {

        text : colors.text,
        bg : colors.bg,
        b : colors.b,

        toString( ){
            if( bg === false ) {
                return colors.text;
            }
            return colors.text+' '+ colors.bg+' '+ colors.b;
        }
    }



    return  _color;
}

function getColor( variant, options = {
    type: 'btn',
    inputType:'',
}){

    if( typeof options.border !== 'boolean' ) {
        options.border = true;
    }


    let hoverVariants = '';

    switch ( options.type ) {
        case 'btn':
            hoverVariants = 'hover:border ' + btnHoverVariants( variant );
            break;
        case 'input':
            hoverVariants = inputHoverVariants( variant );
            if( variant === 'primary') {
                return 'border border-gray-200 text-gray-700 ' + hoverVariants;
            }
            break;

    }


    if( options.type === 'input' ) {
        variant = variant+'-outline';
    }

    let border = false;


    console.log( 'hoverVariants: ', hoverVariants );

    switch ( variant ){




        case "primary":
            return 'bg-gray-700 text-white ' + hoverVariants;
        case "success":
            return 'bg-green-500 text-white ' + hoverVariants;
        case "info":
            return 'bg-blue-600 text-white ' + hoverVariants;
        case "warning":
            return 'bg-yellow-500 text-white ' + hoverVariants;
        case "danger":
            return 'bg-red-700 text-white ' + hoverVariants;


        case "primary-outline":
            border = options.border ? 'border border-gray-700 ':'';
            return  border+' text-gray-700 ' + hoverVariants;
        case "success-outline":
            border = options.border ? 'border  border-green-500 ':'';
            return border+' text-green-500 ' + hoverVariants;
        case "info-outline":
            border = options.border ? 'border  border-blue-600 ':'';
            return border+' text-blue-600 ' + hoverVariants;
        case "warning-outline":
            border = options.border ? 'border  border-yellow-500 ':'';
            return border+' text-yellow-500 ' + hoverVariants;
        case "danger-outline":
            border = options.border ? 'border  border-red-700 ':'';
            return border + ' text-red-700 ' + hoverVariants;

    }
}

export { getColor, getColorByCode }