<template>
  <div :class="disabledClass" class="rounded shadow-sm  bg-white flex">
    <input @input="changeInput" :value="modelValue" v-bind="{...$attrs, ...{ type: getInputType, class: inputClass + ' w-full bg-transparent outline-none'  }}" placeholder="Enter Your Password">
    <password-eye v-if="passwordEye" v-model:eye="password_eye"/>
  </div>
</template>

<script>
import Spinner from "./spinner.vue";
import {getColor} from "./core/color";
import PasswordEye from "./password-key.vue";
export default {
  data() {
    return {
      password_eye: 'off'
    }
  },
  props: {
    inputClass: {type: String, default:''},
    modelValue: { type: String, default:''},
    href: { type:String  },
    disabled: { default: false },
    placeholder: { type: String, default: '' },
    variant: { type: String, default: 'primary' },
    size: { type: String, default: "base" },
    label: { type: String, default: null },
    type: { type: String, default: "text" },
    passwordEye: { type: Boolean, default: false },
  },
  name: "input-text",
  components: {PasswordEye, Spinner},
  created() {

  },
  computed: {
    getInputType(){ 



      if(  this.type === 'password' && this.passwordEye) {
        return this.password_eye === 'on' ? 'text':'password';
      }
      return this.type;
    },
    getSize(){
      // return 'text-base px-2 py-2  ';
      switch ( this.size ) {
        case "xs":
          return 'text-xs px-3 py-1 ';
        case "sm":
          return 'text-base px-3 py-1 ';
        case "md":
          return 'text-base px-3 py-2 ';
        case "lg":
          return 'text-base px-3 py-3 ';
        case "xl":
          return 'text-lg px-3 py-3 ';
        default:
          return 'text-base px-3 py-2'
      }
    },
    getColor(){
      return  getColor(this.variant, {type: 'input'});
    },
    disabledClass(){
      return this.getSize + ( this.disabled ? ' bg-gray-200 text-white pointer-events-none ': ' '+this.getColor);
    }
  },
  // emits: ['update:modelValue'],
  methods: {
    changeInput(e){
      this.$emit( 'update:modelValue', e.target.value );
    }
  }
}
</script>

<style scoped>

</style>