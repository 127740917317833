<template>
   <div class="max-w-4xl mx-auto px-2 p-2 rounded">
    <!-- {{ $store.state.join.confirm.user_genesis.name }} -->
       <form @submit.prevent="findUser" >
            <div class="w-full md:grid md:grid-cols-12 gap-8  bg-white md:h-96 p-4 rounded-md">
                <div class="col-span-6 flex items-center">
                    <img src="../../../assets/login.gif"  />
                </div>
                <div class="col-span-6 flex flex-col justify-center gap-8  mt-8 md:mt-0">
                    <h2 class="text-2xl text-brand">SignIn Confirm</h2>

                    <div class="block w-full relative">
                        <Input
                            type="text"
                            v-model="name"
                            class="w-full focus:outline-none border  text-center"
                            placeholder="Full Name"
                            autocomplete="off"
                            required />
                     </div>
                    <div class="block w-full relative">
                        <Select v-model="gender" class="w-full focus:outline-none border text-center" autocomplete="off" required>
                            <option value=""> Select Gender </option>
                            <option value="1">Male</option>
                            <option value="2">Female</option>
                        </Select>
                     </div>
                      <div class="block w-full">
                        <InputText
                            v-model="password" password-eye
                            type="password"
                            input-class="w-full focus:outline-none text-center"
                            placeholder="Set Password"
                            autocomplete="off"
                        />
                    </div>

                     <div class="flex justify-center">
                        <button type="submit" class="bg-brand text-white py-1.5 px-2 rounded-md flex items-center"> 
                            <spinner v-if="loading" />
                            Next
                        </button>
                     </div>
                </div>
            </div>
       </form>
   </div>
</template>

<script>
import Input from '../../../components/input-new.vue'
import Spinner from '../../../components/spinner.vue'
import Select from '../../../components/select.vue'
import InputText  from '../../../components/input-text.vue'
// import ShowError from "../../../components/show-error.vue";
export default {
   components:{
       Input,
       Spinner,
       Select,
       InputText
   },
   data() {
    return {
        name: this.$store.state.join.confirm.user_genesis ? this.$store.state.join.confirm.user_genesis.name:'',
        phone: this.$route.query.phone || '',
        gender:'',
        email: this.$store.state.join.confirm.user_genesis ? this.$store.state.join.confirm.user_genesis.email:'',
        bmdc: this.$store.state.join.confirm.user_genesis ? this.$store.state.join.confirm.user_genesis.bmdc:'',
        step:1,
        password:'',
        loading: false,
        pageTitle: "Join Page",
    }
  },
  computed:{
    searchUser() {
            // return this.$store.state.join.confirm.user_genesis;
        },
  },

    watch: {
        '$store.state.join.confirm'( data ){
          console.log('',data)  
        }
    },

   methods:{
        findUser(){

            this.loading = true;
            this.formErrors = null;

            if(this.phone.length == 11){
                this.$store.dispatch("auth/registration", {
                    data: { 
                        phone: this.phone,
                        name: this.name,
                        password: this.password,
                        bmdc: this.bmdc,
                        email: this.email,
                        gender: this.gender,
                    }
                }).then(() => {
                    this.$toast.success(`You are join to gcpsc !!!`,{
                        position: "top",
                        duration:3000
                    });
                    this.$router.push("/")
                })
                .finally( (  ) => {
                    this.loading = false
                })
            }
        },
   }
}
</script>

<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>