<template>
  <select class="border-gray-300 focus:border-indigo-300 focus:ring py-2 focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)" ref="input">
        <slot/>
    </select>
</template>

<script>
export default {
 props: ['modelValue'],

    emits: ['update:modelValue'],

    methods: {
        focus() {
            this.$refs.input.focus()
        }
    }
}
</script>

<style scoped>

</style>